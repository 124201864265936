var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "auto" },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "container",
        },
        [
          _c("div", { staticClass: "auto__title" }, [
            _vm._v("Введите номер авто"),
          ]),
          _c("div", { staticClass: "auto__subtitle" }, [
            _vm._v(
              " Для подтверждения приезда или просмотра своего места в очереди "
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "numberForm",
              staticClass: "numberForm",
              attrs: {
                model: _vm.numberForm,
                "validate-on-rule-change": false,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  ref: "plate_truck",
                  staticClass: "item",
                  attrs: { prop: "plate_truck", rules: _vm.rules.plate_truck },
                  scopedSlots: _vm._u([
                    {
                      key: "error",
                      fn: function ({ error }) {
                        return [
                          _c(
                            "div",
                            { staticClass: "customErrors" },
                            [
                              _c("ValidationErrorIcon"),
                              _c("div", { staticClass: "customErrors__text" }, [
                                _vm._v(_vm._s(error)),
                              ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c("SimpleInput", {
                    ref: "input",
                    staticClass: "input",
                    on: {
                      focus: function ($event) {
                        return _vm.resetField("plate_truck")
                      },
                    },
                    model: {
                      value: _vm.numberForm.plate_truck,
                      callback: function ($$v) {
                        _vm.$set(_vm.numberForm, "plate_truck", $$v)
                      },
                      expression: "numberForm.plate_truck",
                    },
                  }),
                ],
                1
              ),
              _c("MainButton", {
                staticClass: "auto__submit",
                attrs: { title: "Найти таймслот" },
                on: {
                  "button-clicked": function ($event) {
                    return _vm.submit("numberForm")
                  },
                },
              }),
            ],
            1
          ),
          _c("MainButton", {
            staticClass: "auto__detail",
            attrs: { title: "Посмотреть текущую очередь", type: "secondary" },
            on: {
              "button-clicked": function ($event) {
                return _vm.$router.push({ name: "terminal-currentQueue" })
              },
            },
          }),
        ],
        1
      ),
      _c("TechSupport"),
      _c(
        "div",
        { staticClass: "simpleKeyboard" },
        [
          _c("SimpleKeyboard", {
            staticClass: "simpleKeyboard__board",
            attrs: { input: _vm.numberForm.plate_truck },
            on: { onChange: _vm.onChange },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <section class="auto">
    <div v-loading="loading" class="container">
      <div class="auto__title">Введите номер авто</div>
      <div class="auto__subtitle">
        Для подтверждения приезда или просмотра своего места в очереди
      </div>

      <el-form
        ref="numberForm"
        class="numberForm"
        :model="numberForm"
        :validate-on-rule-change="false"
      >
        <el-form-item
          ref="plate_truck"
          class="item"
          prop="plate_truck"
          :rules="rules.plate_truck"
        >
          <SimpleInput
            ref="input"
            v-model="numberForm.plate_truck"
            class="input"
            @focus="resetField('plate_truck')"
          />
          <template #error="{ error }">
            <div class="customErrors">
              <ValidationErrorIcon />
              <div class="customErrors__text">{{ error }}</div>
            </div>
          </template>
        </el-form-item>

        <MainButton
          title="Найти таймслот"
          class="auto__submit"
          @button-clicked="submit('numberForm')"
        />
      </el-form>
      <MainButton
        title="Посмотреть текущую очередь"
        type="secondary"
        class="auto__detail"
        @button-clicked="$router.push({ name: 'terminal-currentQueue' })"
      />
    </div>

    <TechSupport />

    <div class="simpleKeyboard">
      <SimpleKeyboard
        class="simpleKeyboard__board"
        :input="numberForm.plate_truck"
        @onChange="onChange"
      />
    </div>
  </section>
</template>

<script>
import { GET_ARRIVAL_PREFIX, GET_LATE_INTERVAL } from '@/store/actions'
import {
  GET_PROFILE_FROM_STATE,
  SET_PROFILE,
} from '@/views/profile/store/actions'
import MainButton from '../components/UI/buttons/MainButton.vue'
import SimpleInput from '../components/UI/controls/SimpleInput.vue'
import SimpleKeyboard from '../components/UI/controls/SimpleKeyboard'
import TechSupport from '@/views/terminalUI/components/TechSupport'
import ValidationErrorIcon from '@/assets/icons/ValidationErrorIcon.vue'

import { diffTimeFromNowTz, onlyTruckNumValidator } from '@/core'
import { mapGetters, mapMutations } from 'vuex'
import API from '@/api/index'

export default {
  name: 'AutoView',
  components: {
    SimpleInput,
    MainButton,
    SimpleKeyboard,
    TechSupport,
    ValidationErrorIcon,
  },
  data() {
    return {
      numberForm: {
        plate_truck: '',
        unload_id: [],
      },
      rules: {
        plate_truck: [
          {
            required: true,
            message: 'Заполните номер авто',
            trigger: 'change',
          },
          {
            required: true,
            validator: (rule, value, callback) => {
              if (onlyTruckNumValidator(value)) {
                callback(
                  new Error(
                    'Формат номера должен быть "м213тт99" или "1212ттт"',
                  ),
                )
              }

              return callback()
            },
            trigger: 'change',
          },
        ],
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: GET_PROFILE_FROM_STATE,
      acceptableLateInterval: GET_LATE_INTERVAL,
      acceptableEarlyMinutes: GET_ARRIVAL_PREFIX,
    }),
    terminalIds() {
      return this.currentUser?.unloads?.map(item => item.id)
    },
  },
  async mounted() {
    // на случай перезагрузки страницы, если в сторе не останется данных пользователя
    if (!this.terminalIds) {
      this.loading = true
      try {
        const { data } = await API.profile.getProfile()

        this.saveUser(data)
      } catch (e) {
        console.log(e.response.data)
      } finally {
        this.loading = false
      }
    }
  },
  methods: {
    ...mapMutations({
      saveUser: SET_PROFILE,
    }),
    submit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.loading = true
          this.numberForm.unload_id = this.terminalIds
          API.timeslots
            .getCurrentTimeslot(this.numberForm)
            .then(res => {
              if (!res.data) {
                this.$router.push({
                  name: 'terminal-withoutTimeslotArrived',
                  params: { truck: this.numberForm.plate_truck },
                })
              } else {
                const response = res.data
                const status = response.truck_status
                const from = response.window_from
                const to = response.window_to

                if (status === 'parked_far' || status === 'arrived') {
                  // уже принят на парковку
                  this.$router.push({
                    name: 'terminal-finallyResponse',
                    params: { timeslot: response },
                  })
                } else if (
                  // приехал вовремя или опоздал в пределах допустимого интервала опоздания
                  // или приехал раньше в пределах допустимого интервала прибытия раньше
                  (diffTimeFromNowTz(from) >= 0 &&
                    diffTimeFromNowTz(to) <= 0) ||
                  (diffTimeFromNowTz(to) > 0 &&
                    diffTimeFromNowTz(to) <=
                      this.acceptableLateInterval * 3600 * 1000) ||
                  (diffTimeFromNowTz(from) < 0 &&
                    Math.abs(diffTimeFromNowTz(from)) <=
                      this.acceptableEarlyMinutes * 60 * 1000)
                ) {
                  this.$router.push({
                    name: 'terminal-intimeArrived',
                    params: { timeslot: response },
                  })
                } else if (diffTimeFromNowTz(from) < 0) {
                  // приехал рано
                  this.$router.push({
                    name: 'terminal-earlyArrived',
                    params: { timeslot: response },
                  })
                } else if (diffTimeFromNowTz(to) > 0) {
                  // опоздал
                  this.$router.push({
                    name: 'terminal-withoutTimeslotArrived',
                    params: { truck: this.numberForm.plate_truck },
                  })
                }
              }
            })
            .catch(error => {
              // ошибка возвращается только в случае, если авто в черном списке
              console.log(error.response.data)
              this.$router.push({
                name: 'terminal-blacklist',
                params: { error: error.response.data },
              })
            })
            .finally(() => (this.loading = false))
        }
      })
    },
    resetField(fieldName) {
      this.$refs[fieldName].clearValidate()
    },
    onChange(input) {
      this.numberForm.plate_truck = input
    },
    onInputChange(input) {
      this.numberForm.plate_truck = input.target.value
    },
  },
}
</script>

<style lang="sass" scoped>
@import '@/assets/styles/terminalUI/terminalUI.sass'
.hg-theme-default
  background-color: inherit

.el-form-item
  margin: 0

.auto
  background-color: $color-bgd-gray
  padding-top: 100px
  display: flex
  flex-direction: column
  font-family: $fontPrimary

  &__title
    margin-bottom: 16px
    font-size: 48px
    font-weight: 500
    line-height: 56px

  &__subtitle
    width: 780px
    margin-bottom: 40px
    font-size: 32px
    font-weight: 500
    line-height: 40px

  .numberForm
    display: flex
    align-items: center
    justify-content: space-between
    gap: 40px
    margin-bottom: 60px

    .item
      flex: 1
      border-radius: 30px
      border: 2px solid $color-second-background

      &.is-error
        border: 1px solid $color-red-primary

      .customErrors
        position: absolute
        display: flex
        align-items: center
        column-gap: 5px

        &__text
          font-family: $fontSecondary
          color: $color-red-primary

  &__submit.el-button
    width: 280px

  &__detail.el-button
    width: 540px
    margin: 0 auto 32px

  .simpleKeyboard
    padding: 14px 70px
    margin-top: 40px
    background-color: $color-second-background
</style>
